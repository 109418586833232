let playState = _.cloneDeep(initialState);

const reset = () => {
    playState = _.cloneDeep(initialState);
    draw();
}

const startPlay = async () => {
    // reset();
    const {marble, parent, queue} = playState.box;
    [...document.getElementsByClassName("drop-buttons")].forEach(el => el.disabled = true);
    while (mode === "play" && (queue.length || parent || marble.type)) {
        await executeStep();
        draw();
    }
    [...document.getElementsByClassName("drop-buttons")].forEach(el => el.disabled = false);
}

const run = (marbleType) => {
    const {marble, parent, queue} = playState.box;
    if (mode === "play" && !(queue.length || parent || marble.type)) {
        queue.push(marbleType);
        draw();
        startPlay();
    }
}

const executeStep = () => {
    const {marble, grid, queue, out} = playState.box;
    if (!marble.type || !marble.pos) {
        if (queue.length) {
            entryCell = grid.flat().find(cell => cell.value === "v");
            marble.pos = [...entryCell.pos];
            marble.type = queue.shift();
        }
    } else {
        const currCell = grid[marble.pos[1]][marble.pos[0]];
        if (["v","","L","R"].includes(currCell.value)) {
            marble.pos[1] += 1;
        } else if (["<","/"].includes(currCell.value)) {
            marble.pos[0] -= 1;
        } else if ([">","\\"].includes(currCell.value)) {
            marble.pos[0] += 1;
        } else if (["."].includes(currCell.value)) {
            marble.type = null;
            marble.pos = null;
        } else if (["?"].includes(currCell.value)) {
            if (marble.type === "L") {
                marble.pos[0] -= 1;
            }
            if (marble.type === "R") {
                marble.pos[0] += 1;
            }
        }
        if (currCell.value === "/") {
            currCell.value = "\\"
        } else if (currCell.value === "\\") {
            currCell.value = "/"
        }
        if (["L","R"].includes(currCell.value)) {
            queue.push(currCell.value)
        }
        if (marble.pos && marble.pos[1] >= 11) {
            out.push(marble.type);
            marble.type = null;
            marble.pos = null;
        }
    }
    return new Promise((resolve) => setTimeout(resolve, 350));
}